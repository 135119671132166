import React, { useState } from 'react';
import { FaGithub, FaLinkedin, FaRegEnvelope, FaRegFilePdf } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './App.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';

function App() {
  const [showLinks, setShowLinks] = useState(false);

  return (
    <div className="main">
      <h1 className="main--title">Harry Lu</h1>
      <p className="main--subtitle">
        I'm a front end web developer based in Toronto. Check out my experiences on my resume or on LinkedIn. More content coming soon!
      </p>
      <div className="main--porthole"></div>
      <div className={`main--image ${showLinks ? "main--image__hidden" : ""}`}>
        <LazyLoadImage
          alt="Harry's face"
          src="headshot.jpg"
          placeholderSrc="headshot.jpg"
          effect="blur"/>
      </div>
      <div className={`main--inner ${showLinks ? "main--inner__visible" : ""}`}>
        <div className="main-links">
          <a className="main--link"
            href="https://github.com/harryzlu"
            target="_blank"
            rel="noopener noreferrer"
            title="Github">
            <FaGithub size="30px" className="main--link-icon"/>
          </a>
          <a className="main--link"
            href="https://www.linkedin.com/in/harryzlu"
            target="_blank"
            rel="noopener noreferrer"
            title="LinkedIn">
            <FaLinkedin size="30px" className="main--link-icon"/>
          </a>
          <a className="main--link"
            href="mailto:harryzlu14@gmail.com"
            title="Email">
            <FaRegEnvelope size="30px" className="main--link-icon"/>
          </a>
          <a className="main--link"
            href="harry_lu_resume_aug_19.pdf"
            target="_blank"
            rel="noopener noreferrer"
            title="Resume">
            <FaRegFilePdf size="30px" className="main--link-icon"/>
          </a>
        </div>
      </div>
      <button
        className="main--button"
        onClick={() => {setShowLinks(!showLinks)}}>
        Click here for now!
      </button>
    </div>
  );
}

export default App;
